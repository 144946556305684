<template>
    <div class="container-fluid">
        <div class="row h-100-vh justify-content-center bg-black">
            <div class="col-12 col-sm-8 col-md-6 col-lg-5 d-flex flex-column justify-content-center bg-white">
                <div>
                    <h4 class="text-center" v-text="$t('messages.accountConfirmation')"> </h4>
                    <hr class="w-75">
                    <div class=" py-4 text-center">
                        <p class="f-18" v-if="accountActivated">
                            <strong v-text="$t('messages.accountActivated')"></strong> <br> <br>
                            <span v-html="$tc('messages.redirectedOn', 1 , {seconds: seconds })">
                            </span>
                        </p>
                        <p class="text-center f-18" v-else>
                            <strong class="text-center " v-text="$t('messages.accountBeiginActive')"> </strong>
                            <br><br>
                            <i class=" fa fa-spin fa-spinner fa-4x my-4 text-success"></i>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return ({
                accountActivated: false,
                seconds: 5,
            });
        },
        methods: {
            fnRedirect() {
                if (this.seconds == 0) {
                    this.$router.push('/login');
                } else {
                    setTimeout(() => {
                        this.seconds = this.seconds - 1;
                        this.fnRedirect();
                    }, 1000);
                }
            },
            fnApiActiveEmail() {
                axios.put('me/confirmation', {
                    reference: this.$route.params.id
                }).then((response) => {
                    setTimeout(() => {
                        this.accountActivated = true;
                        this.fnRedirect();
                    }, 1000);
                    t
                }).catch(() => {});
            }
        },
        mounted() {
            this.fnApiActiveEmail()
        }
    }
</script>

<style>

</style>